/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Swiper, Arctext) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
        ;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        /* Swiper */
        var swiper = new Swiper('.swiperHome', {
            loop: true,
            speed: 400,
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });

        var swiper2 = new Swiper('.swiperLieux', {
            slidesPerView: 3.5,
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            speed:1000,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            breakpoints: {
                30: {
                    slidesPerView: 1,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                },
                480: {
                    slidesPerView: 1.5
                },
                992: {
                    slidesPerView: 3.5
                }
            }
        });

        var swiperFlex = new Swiper('.swiper-flex', {
            centeredSlides: true,
            loop: true,
            speed: 500,
            slidesPerView: 5,
            spaceBetween: 10,
            breakpoints: {
                30: {
                    slidesPerView: 1,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                },
                480: {
                    slidesPerView: 3
                },
                992: {
                    slidesPerView: 5
                }
            }
        });


        var swiperValue = new Swiper('.swiper-value', {
            spaceBetween: 10,
            slidesPerView: 1,
            breakpoints: {
                768: {
                    slidesPerView: 1,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                },
                992: {
                    slidesPerView: 3
                }
            }
        });

        /* Title arc */
        if (jQuery('.curved').length) {
            if (jQuery(window).width() > 768) {
                jQuery('.curved').arctext({
                    radius: 1000
                });
            }
            $(window).on('resize', function () {
                if (jQuery(window).width() > 768) {
                    jQuery('.curved').arctext({
                        radius: 1000
                    });
                }
            });
        }

        /* Anim number */
        jQuery(document).ready(function () {
            if (jQuery('.chiffre').length) {
                var a = 0;
                jQuery(window).scroll(function () {
                    var oTop = jQuery('.chiffre').offset().top - window.innerHeight;

                    if (a == 0 && jQuery(window).scrollTop() > oTop) {
                        jQuery('.counterUp').each(function () {
                            var $this = jQuery(this),
                                countTo = $this.attr('data-count');
                            jQuery({
                                countNum: $this.text()
                            }).animate({
                                    countNum: countTo
                                },
                                {
                                    duration: 2000,
                                    easing: 'swing',
                                    step: function () {
                                        $this.text(Math.floor(this.countNum));
                                    },
                                    complete: function () {
                                        $this.text(this.countNum);
                                        //alert('finished');
                                    }

                                });
                        });
                        a = 1;
                    }
                });
            }
        });

        /* Boutons */
        $(".button_su_inner"
        ).mouseenter(function (e) {
            var parentOffset = $(this).offset();
            var relX = e.pageX - parentOffset.left;
            var relY = e.pageY - parentOffset.top;
            $(this).prev(".su_button_circle").css({"left": relX, "top": relY});
            $(this).prev(".su_button_circle").removeClass("desplode-circle");
            $(this).prev(".su_button_circle").addClass("explode-circle");
        });

        $(".button_su_inner").mouseleave(function (e) {
            var parentOffset = $(this).offset();
            var relX = e.pageX - parentOffset.left;
            var relY = e.pageY - parentOffset.top;
            $(this).prev(".su_button_circle").css({"left": relX, "top": relY});
            $(this).prev(".su_button_circle").removeClass("explode-circle");
            $(this).prev(".su_button_circle").addClass("desplode-circle");
        });


        /* team modal */
        if (jQuery('.mkx-modal-loop').length) {
            jQuery('.mkx-modal-loop').iziModal({});
        }

        /* menu active mobile */
        jQuery(".btn-menu").click(function () {
            $("body").toggleClass("active-mob");
        });

        /* FAQ */
        jQuery('.toggle').click(function(e) {
            e.preventDefault();
            let $this = jQuery(this);

            jQuery('.toggle').removeClass('active');
            $this.addClass('active');

            if ($this.next().hasClass('show')) {
                $this.next().removeClass('show');
                $this.removeClass('active');
                $this.next().slideUp(350);
            } else {
                $this.parent().parent().find('li .inner').removeClass('show');
                $this.parent().parent().find('li .inner').slideUp(350);
                $this.next().toggleClass('show');
                $this.next().slideToggle(350);
            }
        });

        /* Contact block central */
        jQuery('#result').on('change', function() {
            if ( $(this).val() === 'choose-0' ) {
                $('.res').css('display', 'none');
                $('#choose-0').css('display', 'block');
            }else{
                $('.res').css('display', 'none');
                $('#choose-1').css('display', 'block');
            }
        });

        $(document).ready(function() {

            $("#compute_option").html($('#result option:selected').text());
            $("#result").width($("#compute_select").width());

            $('#result').change(function(){
                $("#compute_option").html($('#result option:selected').text());
                $(this).width($("#compute_select").width());
            });

        });

        /* Video Youtube */
        // const players = Array.from(document.querySelectorAll('.player')).map(p => new Plyr(p));
        const player = new Plyr('.player');
        $(document).ready(function() {
            jQuery("iframe").attr("data-ot-ignore", "");
        });

        /* Slider swiper */
        if(jQuery(".swiperHome .swiper-slide").length == 3) {
            jQuery('.swiperHome .swiper-wrapper').addClass( "disabled" );
            jQuery('.swiperHome .swiper-pagination').addClass( "disabled" );
        }

        $body.on('loaderEnd', () => console.log('ended'))
    }
}
